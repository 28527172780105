import React, { useState } from "react";
import { SidebarProps } from "./Sidebar.interface";
import { Avatar } from "../Avatar";
import { MenuItem } from "../MenuItem";
import { ReactComponent as Sparkling } from '../../assets/Sparkling.svg'
import { ReactComponent as Puzzle } from '../../assets/Puzzle.svg'
import { ReactComponent as Search } from '../../assets/Search.svg'
import { ReactComponent as Box } from '../../assets/Box.svg'
import { ReactComponent as LongArrowRight } from '../../assets/LongArrowRight.svg'
import { ReactComponent as Arrows } from '../../assets/Arrows.svg'
import TestImage from '../../assets/temp/TestIncImage.png'
import { styles } from "./Sidebar.styles";
import { Tab } from "./Sidebar.interface";

export const Sidebar = ({ }: SidebarProps) => {

    const [selectedTab, setSelectedTab] = useState<Tab>('chat')

    const onTabSelect = (tab: Tab) => {
        setSelectedTab(tab)
    }


    return <div className={styles.sidebar}>
        <div style={{ display: 'flex', gap: '8px', alignItems: 'center', }}>
            <Avatar name="Iván" surname="Demo" />
            <div style={{ flexGrow: 1 }}>
                <p style={{
                    fontFamily: 'inter',
                    fontWeight: 600,
                    fontSize: '12px',
                }}>Iván Demo</p>
                <p style={{
                    fontFamily: 'inter',
                    fontWeight: 500,
                    fontSize: '10px',
                    color: '#91918F'
                }}>
                    ivan@optiguide.ai
                </p>
            </div>
            <Arrows />
        </div>
        <div className={styles.break} style={{ marginTop: '16px', marginBottom: '12px' }} />
        <div style={{ paddingBottom: '24px' }}>
            <MenuItem<Tab> prefix={<Sparkling width={20} height={20} color="#91918F" />} text="Chat" selectKey="chat" isSelected={selectedTab === 'chat'} onClick={onTabSelect} />
            <MenuItem<Tab> prefix={<Puzzle />} text="Optimization builder" selectKey="builder" isSelected={selectedTab === 'builder'} onClick={onTabSelect} />
            <MenuItem<Tab> prefix={<Search />}
                text="Search"
                selectKey="search"
                isSelected={selectedTab === 'search'}
                suffix={
                    <div className={styles.info}>
                        <p className={styles.infoText}>⌘ K</p>
                    </div>
                }
                onClick={onTabSelect}
            />
        </div>
        <p className={styles.menuTitle} style={{ paddingBottom: '12px' }}>Widgets</p>
        <MenuItem<"1"> selectKey="1" onClick={() => { }} prefix={<Box />} text="Example" />
        <MenuItem<"2"> selectKey="2" onClick={() => { }} prefix={<Box />} text="Example 2" />
        <div className={styles.break} style={{ marginTop: '16px', marginBottom: '16px' }} />
        <p className={styles.menuTitle} style={{ paddingBottom: '12px' }}>Team</p>
        <MenuItem<"3"> selectKey="3" onClick={() => { }} prefix={<img src={TestImage} alt="Test" width='20px' />} text="Example, Inc." suffix={<LongArrowRight />} />
        <div className={styles.break} style={{ position: 'fixed', bottom: 0, width: '212px', marginBottom: '20px', paddingTop: '16px' }}>
            <p style={{ color: '#A4A4A4', margin: 'auto', textAlign: 'center' }}>Convexity v0.0</p>
        </div>
    </div>
}