import React, { useState } from "react";
import { Card } from "../Card";
import { styles } from "./Chat.styles";
import { ReactComponent as PaperClip } from '../../assets/PaperClip.svg'
import { ReactComponent as Database } from '../../assets/Database.svg'
import { ReactComponent as Code } from '../../assets/Code.svg'
import { ReactComponent as DownloadFile } from '../../assets/DownloadFile.svg'
import { ReactComponent as Close } from '../../assets/Close.svg'
import { PillButton } from "../PillButton";
import { ChatProps } from "./Chat.interface";

export const Chat = ({ onSubmit }: ChatProps) => {

    const [text, setText] = useState('')

    return <Card>
        <div className={styles.chat}>

            <div className={styles.content}>
                <textarea className={styles.textArea} value={text} placeholder="Ask about your supply chain" onChange={(e) => setText(e.target.value)} onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        if (e.shiftKey) {
                            e.preventDefault();
                            setText(prev => prev + '\n')
                            return
                        }
                        e.preventDefault();
                        onSubmit(text)
                        setText('')
                    }
                }} />
            </div>
            <div className={styles.footer}>
                <div className={styles.iconContainer}>
                    <PaperClip />
                    <Database />
                    <Code />
                </div>
                <div className={styles.divider} />
                <div className={styles.iconContainer}>
                    <PillButton label="Context: from builder" prefix={<DownloadFile />} variant="primary" />
                    <PillButton label="Context: from builder" suffix={<Close />} variant="secondary" />
                </div>
            </div>
        </div>
    </Card>
}