import React from 'react';
import logo from './logo.svg';
import './App.css';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from 'react-router-dom'
import DashboardPage from './pages/Dashboard';
import ChatPage from './pages/Chat';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<DashboardPage />} />

        <Route path='/chat' element={<ChatPage />} />

      </Routes>
    </Router>
  );
}

export default App;
