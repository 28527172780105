import React from "react";
import { PillButtonProps } from "./PillButton.interface";
import clsx from "clsx";
import { styles } from "./PillButton.styles";

export const PillButton = ({ label, prefix, suffix, variant = 'primary' }: PillButtonProps) => {
    return <button className={clsx(styles.pillButton, styles.variant(variant))}>
        {prefix}
        <p style={{ fontWeight: 500, fontSize: 11 }}>{label}</p>
        {suffix}
    </button>
}