import React from "react";
import { styles } from './MenuItem.styles'
import type { MenuItemProps } from "./MenuItem.interface";
import clsx from "clsx";

export const MenuItem = <T extends string>({ isSelected, text, prefix, suffix, selectKey, onClick }: MenuItemProps<T>) => {
    return <button className={clsx(styles.menuItem, styles.isSelected(isSelected))} onClick={() => {
        onClick(selectKey)
    }} >
        {prefix}
        <p className={clsx(styles.text, styles.isSelectedText(isSelected))}>{text}</p>
        {suffix}
    </button>
}