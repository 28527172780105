import React from "react";
import { Card } from "../Card";
import { ReactComponent as ScienceBottle } from '../../assets/ScienceBottle.svg'
import { ReactComponent as ArrowRight } from '../../assets/ArrowRight.svg'

export const SimulationSuggestion = () => {
    return <div style={{ marginBottom: '40px', display: 'flex', flexDirection: 'column', gap: '12px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', gap: '6px', alignItems: 'center' }}>
                <ScienceBottle />
                <p style={{ fontWeight: 500, fontSize: 13, color: '#555555' }}>Simulation suggestions</p>
            </div>
            <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                <p style={{ fontWeight: 400, fontSize: 12, color: '#A4A4A4' }}>See more</p>
                <ArrowRight />
            </div>
        </div>
        <div style={{ display: 'flex', gap: '16px' }}>
            <Card style={{ flex: 1, maxHeight: '78px' }}>
                <div style={{ padding: '12px', display: 'flex', flexDirection: 'column', gap: '8px' }}>
                    <p style={{ fontWeight: 500, fontSize: 14 }}>What if I expanded the expected goals for Q3 revenue’s forecast?</p>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <p style={{ fontWeight: 500, fontSize: 10, color: '#A4A4A4' }}><span style={{ color: '#0EB345' }}>+4.3%</span> in YoY</p>
                        <ArrowRight />
                    </div>
                </div>
            </Card>
            <Card style={{ flex: 1, maxHeight: '78px' }}>
                <div style={{ padding: '12px', display: 'flex', flexDirection: 'column', gap: '8px' }}>
                    <p style={{ fontWeight: 500, fontSize: 14 }}>What would happen if I cut the sales ops from ‘W1 to W4, Dec’ due to large demand?</p>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <p style={{ fontWeight: 500, fontSize: 10, color: '#A4A4A4' }}><span style={{ color: '#FF0000' }}>-22%</span> in YoY</p>
                        <ArrowRight />
                    </div>
                </div>
            </Card>
        </div>
    </div>
}