import React from "react";
import { styles } from './Avatar.styles'
import { AvatarProps } from "./Avatar.interface";

export const Avatar = ({ name, surname, imageUrl }: AvatarProps) => {
    const firstInitial = name.split('')[0]
    const lastInitial = surname.split('')[0]


    return <div className={styles.avatar}>
        <p className={styles.initials}>{firstInitial + lastInitial}</p>
    </div>
}