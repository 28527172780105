import React from "react";
import { Link } from "react-router-dom";

const ChatPage = () => {
    return <div>
        <p>Chat</p>
        <Link to='/'>Home</Link>
    </div>
}

export default ChatPage